import React, {
  useState,
  // useEffect
} from "react";
import { EventCard } from "@molecules";
import { Container, Dropdown } from "@atoms";
// import useCategoriesData from "@staticQueries/CategoriesQuery";
// import { navigate } from "gatsby";
import loadable from "@loadable/component";
// import convertToOptions from "@utils/convertToOptions";

const Pagination = loadable(() => import("../molecules/Pagination"));

const EventList = ({ events, type, enableFeaturedEvent }) => {
  const [date, setDate] = useState("future");

  const filteredEvents = (events || [])
    .filter(a => {
      // we don't filter the type here since this is done at the query level
      // const aTopics = a.topics.map(t => t.slug);
      if (a.startDateTime && date !== "all") {
        return date === "future"
          ? (a.endDateTime || a.startDateTime).getTime() > new Date().getTime()
          : (a.endDateTime || a.startDateTime).getTime() < new Date().getTime();
      }
      return date === "all";
    })
    .sort((a, b) => {
      if (a.startDateTime && b.startDateTime)
        return date === "future"
          ? a.startDateTime.getTime() - b.startDateTime.getTime()
          : b.startDateTime.getTime() - a.startDateTime.getTime();

      return !!a.startDateTime;
    });

  return (
    <section className="mt-8 md:mt-12">
      <Container variant="xs">
        <div className="relative items-center lg:flex">
          <div className="flex h-8 items-center font-bold lg:absolute lg:left-0 lg:-translate-x-full lg:transform lg:pr-3">
            Filter By:
          </div>
          <div className="flex w-full flex-wrap gap-6">
            <Dropdown
              model={[date, setDate]}
              className="w-full md:w-1/3"
              options={[
                // { all: "All Events" },
                { future: "Upcoming Events" },
                { past: "Past Events" },
              ]}
            />
            {/* <Dropdown
              model={[typeFilter, setTypeFilter]}
              className="w-full md:w-1/3"
              options={[
                { all: "All Events" },
                ...eventCategory.map(convertToOptions),
              ]}
            /> */}
            {/* <Dropdown
              model={[topicFilter, setTopicFilter]}
              className="w-full md:w-1/3"
              options={[{ all: "All Topics" }, ...topics.map(convertToOptions)]}
            /> */}
          </div>
        </div>
        {/* the events */}
        <ul className="mt-8 grid grid-cols-1 gap-12 md:mt-12 md:gap-12">
          {!!filteredEvents.length && (
            <Pagination
              enableFeaturedEvent={enableFeaturedEvent}
              data={filteredEvents}
              component={EventCard}
              pageSize={6}
              rangeLimit={1}
              showNavigation
            />
          )}
          {!filteredEvents.length && "There are no upcoming events"}
          {/* {!filteredEvents.length && (
            <p className="mx-auto max-w-sm text-center text-sm leading-tight">
              No events match the parameters of your filters. <br />
              Check out all events for information on other events.
            </p>
          )} */}
        </ul>
      </Container>
    </section>
  );
};

export default EventList;
